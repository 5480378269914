import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HtmlNotificationService {
    open(
        message: string,
        title: string,
        tag?: string,
        data?: unknown,
        onClick?: (data: any) => void,
    ): void {
        Notification.requestPermission().then((result) => {
            //ok: granted
            //ko: denied
            if (result === 'granted') {
                const notificationToast = new Notification(title, {
                    body: message,
                    tag,
                    icon: './assets/images-2.0/main-logo_72x72.png',
                });
                notificationToast.onclick = function () {
                    if (onClick != null && data != null) {
                        onClick(data);
                    }

                    notificationToast.close();
                };
            }
        });
    }
}
